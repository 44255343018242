import { type DtoUserAuthenticatedResponse } from '@lp-lib/api-service-client/public';

import {
  APIServiceURL,
  publicFetchAPIService,
  type PublicRequestInit,
} from '../../src/services/public';

export async function fetchAuthVerify(token: string | null) {
  const url = new APIServiceURL('/auth/verify');
  const init: PublicRequestInit = { token, method: 'POST' };
  const resp = await publicFetchAPIService<DtoUserAuthenticatedResponse>(
    url,
    init
  );
  return resp.json;
}
